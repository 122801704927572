import React       from 'react';
import { graphql } from 'gatsby';

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box
} from '@chakra-ui/react';

import Heading  from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer   from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper  from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import SEO      from '@interness/web-core/src/components/modules/SEO/SEO';
import Lightbox from '@interness/web-core/src/components/media/Lightbox/Lightbox';
import CallToAction      from '@interness/theme-twentytwenty/src/components/CallToAction/CallToAction';

function GaleriePage(props) {
  return (
    <>
      <SEO title={'Galerie'}/>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading>Galerie</Heading>
          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex='1' textAlign='left'>
                    Anstecknadeln
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Lightbox images={props.data.anstecknadel.images} thumbnails={props.data.anstecknadel.thumbnails} fromApi/>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex='1' textAlign='left'>
                    Armbänder / Armreifen
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Lightbox images={props.data.armband.images} thumbnails={props.data.armband.thumbnails} fromApi/>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex='1' textAlign='left'>
                    Colliers
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Lightbox images={props.data.colliers.images} thumbnails={props.data.colliers.thumbnails} fromApi/>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex='1' textAlign='left'>
                    Manschettenknöpfe
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Lightbox images={props.data.manschetten.images} thumbnails={props.data.manschetten.thumbnails} fromApi/>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex='1' textAlign='left'>
                    Ohrringe
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Lightbox images={props.data.ohrringe.images} thumbnails={props.data.ohrringe.thumbnails} fromApi/>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex='1' textAlign='left'>
                    Ringe
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Lightbox images={props.data.ringe.images} thumbnails={props.data.ringe.thumbnails} fromApi/>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex='1' textAlign='left'>
                    Skizzen
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Lightbox images={props.data.skizzen.images} thumbnails={props.data.skizzen.thumbnails} fromApi/>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex='1' textAlign='left'>
                    Trauringe
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Lightbox images={props.data.trauringe.images} thumbnails={props.data.trauringe.thumbnails} fromApi/>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex='1' textAlign='left'>
                    Verschiedenes
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Lightbox images={props.data.verschiedenes.images} thumbnails={props.data.verschiedenes.thumbnails} fromApi/>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Spacer/>
        </section>
      </Wrapper>
      <CallToAction/>
    </>
  )
}

export default GaleriePage;

export const query = graphql`
    query {
        anstecknadel: directusMediaCollection(name: {eq: "galerie-anstecknadel"}) {
            ...LightboxGalleryGrid
        }
        armband: directusMediaCollection(name: {eq: "galerie-armband-armreifen"}) {
            ...LightboxGalleryGrid
        }
        colliers: directusMediaCollection(name: {eq: "galerie-colliers"}) {
            ...LightboxGalleryGrid
        }
        manschetten: directusMediaCollection(name: {eq: "galerie-manschetten"}) {
            ...LightboxGalleryGrid
        }
        ohrringe: directusMediaCollection(name: {eq: "galerie-ohrringe"}) {
            ...LightboxGalleryGrid
        }
        ringe: directusMediaCollection(name: {eq: "galerie-ringe"}) {
            ...LightboxGalleryGrid
        }
        skizzen: directusMediaCollection(name: {eq: "galerie-skizzen"}) {
            ...LightboxGalleryGrid
        }
        trauringe: directusMediaCollection(name: {eq: "galerie-trauringe"}) {
            ...LightboxGalleryGrid
        }
        verschiedenes: directusMediaCollection(name: {eq: "galerie-verschiedenes"}) {
            ...LightboxGalleryGrid
        }
    }
`;